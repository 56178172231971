<template>
  <v-card class="pb-2">
    <v-card-item>
      <v-card-title>Glossary</v-card-title>
    </v-card-item>
    <v-text-field
      v-model="search"
      :append-inner-icon="mdiMagnify"
      label="Search"
      class="mx-8"
      :messages="`${filteredItems.length} items`"
      :hide-details="false"
      clearable
      autofocus
      @click:clear="search = ''"
    />
    <v-list
      v-if="filteredItems.length"
      class="max-h-[400px] px-4 pt-4"
    >
      <v-list-item
        v-for="item in filteredItems"
        :key="item.title"
        class="!py-3"
      >
        <v-list-item-title class="mb-2">
          <span v-highlight>
            {{ item.title }}
          </span>
          <v-chip
            v-if="item.code"
            rounded
            size="x-small"
            color="primary"
            class="mx-2"
          >
            <span v-highlight>{{ item.code }}</span>
          </v-chip>
        </v-list-item-title>
        <p
          v-highlight
          class="text-sm"
        >
          {{ item.description }}
        </p>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts" setup>
import { mdiMagnify } from "@mdi/js";
import type { Directive } from "vue";
import { computed, ref } from "vue";

import { useTrackEvent } from "@/composables/useTrackEvent";

type GlossaryItem = {
  title: string;
  description: string;
  code: string;
};

const search = ref("");

const items: GlossaryItem[] = [
  {
    title: "Account",
    code: "",
    description:
      "The Account is the level of the financing arrangement. There can be more than one Account per Fund and Prime Broker relationship.",
  },
  {
    title: "Accrual (Position CCY)",
    code: "",
    description:
      "The calculated financing accrual in position currency (e.g. Net Indebtedness accrual, Cash Credit accrual, Borrow Cash Collateral accrual)",
  },
  {
    title: "Accrual (USD)",
    code: "",
    description:
      "The calculated financing accrual in USD (e.g. Net Indebtedness accrual, Cash Credit accrual, Borrow Cash Collateral accrual)",
  },
  {
    title: "Adjustment accrual (Position CCY)",
    code: "",
    description:
      "The calculated financing adjustment accrual in position currency (e.g. Asset Based Financing accrual adjustment)",
  },
  {
    title: "Adjustment accrual (USD)",
    code: "",
    description:
      "The calculated financing adjustment accrual in USD (e.g. Asset Based Financing accrual adjustment)",
  },
  {
    title: "Adjustment Notional (Position CCY)",
    code: "",
    description:
      "The notional in position currency to which the adjustment fee is applied in financing calculations",
  },
  {
    title: "Adjustment Notional (USD)",
    code: "",
    description:
      "The notional in USD to which the adjustment fee is applied in financing calculations",
  },
  {
    title: "Adjustment Rate",
    code: "",
    description: "The Adjustment rate applied in the financing calculations",
  },
  {
    title: "Applied FX Rate",
    code: "",
    description: "The FX rate applied in the financing calculations",
  },
  {
    title: "Attr BCC Notional (USD)",
    code: "",
    description:
      "The Attributed Borrow Cash Collateral funding notional in USD",
  },
  {
    title: "Attr BCC Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Borrow Cash Collateral Spread accrual in USD (i.e. applied to the short security for a covered short, or remaining on the Borrow for an overborrow)",
  },
  {
    title: "Attr BCC Spread Rate",
    code: "",
    description:
      "The Attributed Borrow Cash Collateral Spread Rate (i.e. applied to the short security for a covered short, or remaining on the Borrow for an overborrow)",
  },
  {
    title: "Attr Benchmark Notional (USD)",
    code: "",
    description: "The Attributed benchmark funding notional in USD",
  },
  {
    title: "Attr Benchmark Accrual (Position CCY)",
    code: "",
    description:
      "The Attributed Benchmark Accrual (Position CCY) is the accrual due to the benchmark financing for a security position in its position currency. This reflects the benchmark interest applied to the short market value for a short or the cost of borrowing cash applied for a long",
  },
  {
    title: "Attr Benchmark Accrual (USD)",
    code: "",
    description:
      "The Attributed Benchmark Accrual (USD) is the the accrual due to the benchmark financing for a security position in USD. This reflects the benchmark interest applied to the short market value for a short or the cost of borrowing cash applied for a long",
  },
  {
    title: "Attr Benchmark Rate",
    code: "",
    description:
      "The Attributed Benchmark Rate is the rate of the benchmark in the currency of the position. This reflects the interest rate applied to the short market value for a short or for the cost of borrowing cash applied for a long",
  },
  {
    title: "Attr Borrow Notional (USD)",
    code: "",
    description: "The Attributed Borrow notional in USD",
  },
  {
    title: "Attr Borrow Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Borrow Spread accrual in USD (i.e. the Borrow fee accrual on the short security for a covered short, or remaining on the Borrow for an overborrow)",
  },
  {
    title: "Attr Borrow Spread Rate",
    code: "",
    description:
      "The Attributed Borrow spread rate (i.e. the Borrow fee applied to the short security for a covered short, or remaining on the Borrow for an overborrow)",
  },
  {
    title: "Attr Cash Credit Notional (USD)",
    code: "",
    description: "The Attributed Cash Credit funding notional in USD",
  },
  {
    title: "Attr CC Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Cash Credit spread accrual in USD (i.e. all Cash Credit spread accruals are attributed to their own position)",
  },
  {
    title: "Attr CC Spread Rate",
    code: "",
    description:
      "The Attributed Cash Credit Spread Rate (i.e. same as the Cash Credit Spread Rate as they are attributed to their own position)",
  },
  {
    title: "Attr NI Notional (USD)",
    code: "",
    description: "The Attributed Net Indebtedness funding notional in USD",
  },
  {
    title: "Attr NI Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Net Indebtedness Spread accrual in USD (i.e. applied to the longs securing the Net Indebtedness, or remaining on the Net Indebtedness if unsecured)",
  },
  {
    title: "Attr NI Spread Rate",
    code: "",
    description:
      "The Attributed Net Indebtedness spread rate applied to a long position (where secured) or to the Net Indebtedness position when unsecured",
  },
  {
    title: "Attr Spread Notional (USD)",
    code: "",
    description:
      "The Attributed Spread which shows a summary view of the different attributed notionals applied to the different types of spreads",
  },
  {
    title: "Attr Spread Rate",
    code: "",
    description:
      "The Attributed Spread Rate which shows a summary view of the different spread rates attributed to the different types of positions",
  },
  {
    title: "Attr Swap Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Swap spread accrual in USD (i.e. all Swap spread accruals are attributed to their own position)",
  },
  {
    title: "Attr Swap Spread Rate",
    code: "",
    description:
      "The Attributed Swap Spread Rate (i.e. same as the Swap Spread Rate as they are attributed to their own position)",
  },
  {
    title: "Attr Swaps Notional (USD)",
    code: "",
    description: "The Attributed Swap funding notional in USD",
  },
  {
    title: "Attr Total Accrual (USD)",
    code: "",
    description:
      "The Attributed total accrual in USD for a position (i.e. including attributed benchmark accrual and spread accrual)",
  },
  {
    title: "Attr Total Rate",
    code: "",
    description:
      "The Attributed Total Rate which shows a summary view of the different rates (benchmark and spread) attributed to the different types of positions",
  },
  {
    title: "Attr Total Spread Accrual (USD)",
    code: "",
    description:
      "The Attributed Total Spread accrual in USD (i.e. the sum of all attributed spread accruals across all positions will equal the total Spread Interest (USD) as all spreads will be attributed to a position)",
  },
  {
    title: "Benchmark",
    code: "",
    description:
      "The official benchmark interest rate applied for financing accruals. The benchmark that will be referenced is agreed in advance with the Prime Broker.",
  },
  {
    title: "Benchmark accrual (Position CCY)",
    code: "",
    description:
      "The calculated financing benchmark accrual in position currency (e.g. Net Indebtedness benchmark accrual, Cash Credit benchmark accrual, Borrow Cash Collateral benchmark accrual)",
  },
  {
    title: "Benchmark accrual (USD)",
    code: "",
    description:
      "The calculated financing benchmark accrual in USD (e.g. Net Indebtedness benchmark accrual, Cash Credit benchmark accrual, Borrow Cash Collateral benchmark accrual)",
  },
  {
    title: "Benchmark Notional (Position CCY)",
    code: "",
    description:
      "The notional in position currency to which the benchmark rate is applied to in financing calculations",
  },
  {
    title: "Benchmark Notional (USD)",
    code: "",
    description:
      "The notional in USD to which the benchmark rate is applied to in financing calculations",
  },
  {
    title: "Benchmark Rate",
    code: "",
    description: "The Benchmark Rate applied in the financing calculations",
  },
  {
    title: "Borrow",
    code: "B",
    description:
      "The Borrow is the position that reflects the borrow of the security that is needed in order to cover a short position, or have an overborrow in place (i.e. giving the ability for a future short position to be put on )",
  },
  {
    title: "Borrow Cash Collateral",
    code: "BCC",
    description:
      "The value of the cash required for the PB to post against the Borrow of the security (either to cover a short or for an overborrow). The BCC is either calculated from the Short Market Value of the shorts with applicable haircuts, or is taken directly from the PB reports. The BCC is taken from the net cash balance at the PB to leave either with Net Indebtedness or Cash Credit in each currency.",
  },
  {
    title: "Borrow Category",
    code: "",
    description:
      "The category of the Borrow, either GC (General Collateral) or HTB (Hard to Borrow). The threshold to determine whether a Borrow is GC or HTB can be defined per client.",
  },
  {
    title: "Cash / Not Cash",
    code: "",
    description:
      "A flag to show whether a position is a cash position (e.g. 'Cash Credit', 'Net Indebtedness', 'Borrow Cash Collateral') or not",
  },
  {
    title: "Cash Credit",
    code: "CC",
    description:
      "The Cash Credit balances calculated when Borrow Cash Collateral is subtracted from the Net Cash balance to leave a positive balance in a certain currency",
  },
  {
    title: "CUSIP",
    code: "",
    description:
      "The Identification number assigned to stocks and registered bonds by The Committee on Uniform Securities Identification Procedures (CUSIP)",
  },
  {
    title: "Date",
    code: "",
    description: "The effective date of the financing calculations",
  },
  {
    title: "Eligible for Financing",
    code: "",
    description:
      "A flag to show whether a long security is eligible to be used as collateral to secure Net Indebtedness",
  },
  {
    title: "Exchange",
    code: "",
    description:
      "The exchange on which the product is traded (e.g. New York Stock Exchange, Six Swiss Exchange, Deutsche Boerse AG)",
  },
  {
    title: "Fund",
    code: "",
    description: "The Fund (i.e. legal entity)",
  },
  {
    title: "Funding Notional (USD)",
    code: "",
    description:
      "The Funding Notional used in the financing calculations in USD for a particular financing bucket (e.g. Net Indebtedness, Cash Credit, Borrow, Borrow Cash Collateral, Long Swap, Short Swap)",
  },
  {
    title: "GC",
    code: "",
    description:
      "The categorisation of a borrowed security that is deemed as “General Collateral’ and therefore incurs a default, pre-agreed financing spread",
  },
  {
    title: "HTB",
    code: "",
    description:
      "The categorisation of a borrowed security that doesn’t qualify as “GC” and therefore incurs a bespoke financing spread, determined daily by the PB on a trade by trade basis",
  },
  {
    title: "ISIN",
    code: "",
    description:
      "The 12 digit alpha numeric International Securities Identification Number (ISIN) assigned to a security",
  },
  {
    title: "ISO",
    code: "",
    description: "The ISO code defines the country code of a product",
  },
  {
    title: "Kayenta Tier",
    code: "",
    description:
      "Kayenta's grouping of long assets used as collateral against Net Indebtedness, grouped by Kayent Spread Tier (KST) buckets (e.g. KST1, KST2, KST3). Buckets can be defined per client.",
  },
  {
    title: "Last Rate Fixing Date",
    code: "",
    description:
      "The date on which the rate was fixed for the period of financing calculation",
  },
  {
    title: "Long Swap Strike Notional",
    code: "LSSN",
    description:
      "The USD strike notional of long swaps which is typically what drives the benchmark financing accruals",
  },
  {
    title: "Long Swap Underlying Notional",
    code: "LSUN",
    description:
      "The USD market value of the underlying securities to long swaps. On an equity fixing date, this is usually the same as the Long Swap Strike Notional (LSSN) but during the reset period these two amounts deviate due to the underlying security performance.",
  },
  {
    title: "Market Sector",
    code: "",
    description:
      "The sector in which the product sits as defined by Kayenta sector categorisation (e.g. Energy, Financials, Industrials)",
  },
  {
    title: "Market Value (Position CCY)",
    code: "",
    description:
      "The mark to market value of a position in its position currency",
  },
  {
    title: "Market Value (USD)",
    code: "",
    description: "The mark to market value of a position in USD",
  },
  {
    title: "Net Indebtedness",
    code: "NI",
    description:
      "The Net Indebtedness balances calculated when Borrow Cash Collateral is subtracted from the Net Cash balance to leave a negative balance in a certain currency. This is the amount of cash per currency that is being borrowed from the PB and the cost of that borrowing is determined by the quality of the long asset collateralising (i.e. securing) that Net Indebtedness. If there is no asset to collateralise this Net Indebtedness then this is unsecured.",
  },
  {
    title: "Outperformance Trade",
    code: "",
    description:
      "A security loaned to the Prime Broker in exchange for a financing payment (which would occur where the long security has lending value in the securities lending market)",
  },
  {
    title: "Overborrow",
    code: "",
    description:
      "A borrowed security where there is no associated short position and the borrow is therefore held as an excess borrow",
  },
  {
    title: "PB Collateral Benchmark Rate",
    code: "",
    description:
      "The benchmark rate assigned to a financing calculation by the PB",
  },
  {
    title: "PB Collateral Spread Rate",
    code: "",
    description:
      "The spread rate assigned to a long position by the PB based on the quality of the asset to be used as collateral to secure indebtedness",
  },
  {
    title: "Physical Long Market Value",
    code: "LMV",
    description:
      "The market value of long securities positions in cash PB accounts (Long swaps are therefore not part of LMV)",
  },
  {
    title: "Physical Short Market Value",
    code: "SMV",
    description:
      "The market value of short securities positions in cash PB accounts (Short swaps are therefore not part of SMV)",
  },
  {
    title: "Position Currency",
    code: "",
    description: "The currency of the position",
  },
  {
    title: "Position Type",
    code: "",
    description:
      "The type of the position (e.g. 'Long', 'Borrow', 'Net Indebtedness')",
  },
  {
    title: "Prime Broker",
    code: "",
    description: "The Prime Broker counterparty",
  },
  {
    title: "Product Description",
    code: "",
    description: "The description of the product",
  },
  {
    title: "Product Type",
    code: "",
    description:
      "The type of the product (e.g. 'Common Stock', 'Convertible Bonds', 'Cash')",
  },
  {
    title: "Quantity",
    code: "",
    description: "The number of units held of a product",
  },
  {
    title: "Rebate accrual",
    code: "",
    description:
      "The net accrual with respect to a Borrow (specifically for US securities), calculated by combing the Borrow Cash Collateral accrual with the Borrow fee accrual.",
  },
  {
    title: "Short Swap Strike Notional",
    code: "SSSN",
    description:
      "The USD strike notional of short swaps which is typically what drives the benchmark financing accruals",
  },
  {
    title: "Short Swap Underlying Notional",
    code: "SSUN",
    description:
      "The USD market value of the underlying securities to short swaps. On an equity fixing date, this is usually the same as the Short Swap Strike Notional (LSSN) but during the reset period these two amounts deviate due to the underlying security performance.",
  },
  {
    title: "Spread accrual (Position CCY)",
    code: "",
    description:
      "The calculated financing spread accrual in position currency (e.g. Net Indebtedness Spread accrual, Borrow Cash Collateral spread accrual, Cash Credit Spread accrual)",
  },
  {
    title: "Spread accrual (USD)",
    code: "",
    description:
      "The calculated financing spread accrual in USD (e.g. Net Indebtedness Spread accrual, Borrow Cash Collateral spread accrual, Cash Credit Spread accrual)",
  },
  {
    title: "Spread Notional (Position CCY)",
    code: "",
    description:
      "The notional in position currency to which the spread rate is applied to in financing calculations",
  },
  {
    title: "Spread Notional (USD)",
    code: "",
    description:
      "The notional in USD to which the spread rate is applied to in financing calculations",
  },
  {
    title: "Spread Rate",
    code: "",
    description:
      "The Spread Rate applied in the financing calculations (i.e. for Net Indebtedness this is the Net Indebtedness Spread, for Short Swaps this is the Short Swap Spread, for Borrows this is the Borrow Fee)",
  },
  {
    title: "Strike Notional (Position CCY)",
    code: "",
    description:
      "The value of the strike of a Swap or Option in it's position currency",
  },
  {
    title: "Strike Notional (USD)",
    code: "",
    description: "The value of the strike of a Swap or Option in USD",
  },
  {
    title: "Swap ID",
    code: "",
    description: "The swap identifier used by the counterparty",
  },
  {
    title: "Tier",
    code: "",
    description:
      "The PB categorisation of long assets used as collateral against Net Indebtedness (e.g. 'Fundable', 'Unfundable'). Where no PB categorisation has been found this will show as 'KAYENTA_TIERING_GRADE_MISSING')",
  },
  {
    title: "Total Cash Balance",
    code: "",
    description:
      "The total cash balance per currency across Cash Credits, Net Indebtedness and Borrow Cash Collateral. This is often the Net Cash reported by PBs.",
  },
  {
    title: "Total Indebtedness",
    code: "",
    description:
      "The total borrowings in cash PB accounts across securities and cash in USD. This includes gross currency Net Indebtedness balances and Borrow balances.",
  },
  {
    title: "Total Rate",
    code: "",
    description:
      "The total rate applied in the financing calculations (including benchmark and spread)",
  },
  {
    title: "Underlying Notional (Position CCY)",
    code: "",
    description:
      "The value of the underlying to a Swap or Option in its position currency",
  },
  {
    title: "Underlying Notional (USD)",
    code: "",
    description: "The value of the underlying to a Swap or Option in USD",
  },
  {
    title: "Underlying Price (Position CCY)",
    code: "",
    description: "The underlying price in the position currency",
  },
  {
    title: "Underlying Price CCY",
    code: "",
    description: "The currency of the underlying price of the product",
  },
  {
    title: "Unsecured",
    code: "",
    description:
      "Unsecured balances are Net Indebtedness balances that are not collateralized by eligible collateral and therefore attract a penal unsecured financing rate",
  },
  {
    title: "Utilized / Excess Collateral",
    code: "",
    description:
      "Utilized Collateral is long securities being posted against Net Indebtedness, whereas Excess Collateral are long securities that are eligible for Debit Financing but not being used and therefore free to be moved to alternative financing arrangements without impacting the financing cost of the account they are currently in.",
  },
].sort((a, b) => a.title.localeCompare(b.title));

const filteredItems = computed(() => {
  if (!search.value) return items;

  const regex = safeRegExp(search.value, "gi");

  return items.filter((item) =>
    Object.values(item).some((value) => value && regex.test(value))
  );
});

useTrackEvent().watch({
  name: "Search",
  component: "InlineGlossaryCard",
  value: search,
});

const vHighlight: Directive<HTMLElement> = (el) => {
  const innerText: string = el.innerText;
  const regex = new RegExp(search.value, "gi");
  const highlighted = innerText.replace(
    regex,
    (match) => `<span class="bg-yellow-200">${match}</span>`
  );
  el.innerHTML = highlighted;
};
</script>
